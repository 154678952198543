/*
 * 업무구분: 프리미엄 고객사랑 서비스
 * 화 면 명: MSPCM307P
 * 화면설명: 결과입력
 * 접근권한: FC
 * 작 성 일: 2022.07.04
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container title="결과입력" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="ns-pss-name">
          <span class="name">{{custInfo.custNm}}</span>
          <mo-badge class="badge-sample-badge blue" text="회사제공" shape="status">{{custInfo.custSrvcTypCdNm}}</mo-badge>
        </div>
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-result-area">
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-input-box">
            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
              <mo-segment-wrapper solid primary v-model="visitValues.key">
                <mo-segment-button value="1" >방문</mo-segment-button>
                <mo-segment-button value="2">미방문</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </ur-box-container>
          <!-- 방문 클릭시 라디오 버튼 -->
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" v-if="visitValues.key === '1'">
            <div class="ns-radio-list">
              <mo-radio v-model="radioSelected" value="a" @input="fn_CheckSelectedItem">기계약상황확인</mo-radio>
              <mo-radio v-model="radioSelected" value="b" @input="fn_CheckSelectedItem">컨설팅(통합자산분석,보장분석)</mo-radio>
              <mo-radio v-model="radioSelected" value="c" @input="fn_CheckSelectedItem">방문물품만전달</mo-radio>
              <mo-radio v-model="radioSelected" value="d" @input="fn_CheckSelectedItem">보험금청구</mo-radio>
              <mo-radio v-model="radioSelected" value="e" @input="fn_CheckSelectedItem">신상품/이벤트소개</mo-radio>
            </div>
          </ur-box-container>
          <!-- 미방문 클릭시 라디오 버튼 -->
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" v-else>
            <div class="ns-radio-list">
              <mo-radio v-model="radioSelected" value="3" @input="fn_CheckSelectedItem">고객변심으로방문불가</mo-radio>
              <mo-radio v-model="radioSelected" value="4" @input="fn_CheckSelectedItem">FC개인사정으로미방문</mo-radio>
              <mo-radio v-model="radioSelected" value="5" @input="fn_CheckSelectedItem">담당FC해촉</mo-radio>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="radioSelected === ''" @click="fn_SubmitCustSrvcRslt()">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM307P",
    screenId: "MSPCM307P",
    props: {
      'selectedCustInfo': [Object, Array]
    },
    components: {
      
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        visitValues: [{key: '1', label: '방문'}],
        visit: [
          {key: '1', label: '방문'},
          {key: '2', label: '미방문'}
        ],
        //radioStrValue: '',
        radioSelected: '',
        custInfo: []
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      console.log('MSPCM307P mounted')
      let lv_vm = this
      lv_vm.visitValues = []
      lv_vm.custInfo = lv_vm.$props.selectedCustInfo
      let custSrvcRsltCd = lv_vm.custInfo.custSrvcRsltCd

      if (custSrvcRsltCd === '3' || custSrvcRsltCd === '4' || custSrvcRsltCd === '5' ) {
        lv_vm.visitValues = lv_vm.visit[1]
      } else {
        lv_vm.visitValues = lv_vm.visit[0]
      }
      lv_vm.radioSelected = lv_vm.custInfo.custSrvcRsltCd
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_CheckSelectedItem(val){
        this.radioStrValue = val
      },

      /*******************************************************
       * Function명  : fn_SubmitCustSrvcRslt
       * 설명        : 활동결과 저장 메서드
       ******************************************************/      
      fn_SubmitCustSrvcRslt: function () {
        var lv_vm = this
        var pParam = {
          objYm: lv_vm.custInfo.objYm,
          cnsltNo: lv_vm.custInfo.cnsltNo,
          custId: lv_vm.custInfo.custId,
          contNo: lv_vm.custInfo.contNo,
          custSrvcAplNo: lv_vm.custInfo.custSrvcAplNo,
          custSrvcRsltCd: lv_vm.radioSelected,
          custSrvcTypCd: lv_vm.custInfo.custSrvcTypCd,
          custNm: lv_vm.custInfo.custNm,
          chnlCustId: lv_vm.custInfo.chnlCustId,
          rrnFrnoNo: lv_vm.custInfo.rrnFrnoNo
        }
        
        var trnstId = 'txTSSCM51I2'
        lv_vm.post(lv_vm, pParam, trnstId, 'U')
          .then(function (response) {
            if (response !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU044') {
            // 페이지 reloading 해야함
              lv_vm.$addSnackbar('방문결과 입력 되었습니다.')
              lv_vm.fn_OnPositive()
            } else {
              lv_vm.$addSnackbar(response.msgComm.msgDesc)
              lv_vm.fn_OnNegative ()
            }
          })
      },

      /*******************************************************
       * Function명  : onPositive
       * 설명        : fdpConfirm 예 버튼 클릭 시, 콜백 함수
       ******************************************************/
      fn_OnPositive () {
        console.log('--------------------------------------')
        console.log('MSPCM307P emit onPositive307p from fn_OnPositive')
        console.log('--------------------------------------')
        this.$emit('onPositive307p')
      },

      /*******************************************************
       * Function명  : onPositive
       * 설명        : fdpConfirm 아니오 버튼 클릭 시, 콜백 함수
       ******************************************************/
      fn_OnNegative () {
        console.log('--------------------------------------')
        console.log('MSPCM307P emit onNegative307p from fn_OnNegative')
        console.log('--------------------------------------')
        this.$emit('onNegative307p')
      },

      /*****************************************
       * Function명  : fn_Back
       * 설명        : 뒤로가기 (history back)
       *****************************************/
      fn_Back () {
        console.log('--------------------------------------')
        console.log('MSPCM307P emit onNegative307p from fn_Back')
        console.log('--------------------------------------')
        this.$emit('onNegative307p')
      }
    }
  };
</script>
<style scoped>
</style>