import { render, staticRenderFns } from "./MSPCM307P_BS.vue?vue&type=template&id=609f0dd8&scoped=true&data-page-screen-size=300x500&"
import script from "./MSPCM307P_BS.vue?vue&type=script&lang=js&"
export * from "./MSPCM307P_BS.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609f0dd8",
  null
  
)

export default component.exports