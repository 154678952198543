/*
 * 업무구분: 프리미엄 고객사랑 서비스
 * 화 면 명: MSPCM311P
 * 화면설명: 프사서 상세조회(신계약현황/활동내역)
 * 접근권한: FC
 * 작 성 일: 2022.07.05
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container title="상세조회" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="ns-pss-name">
          <span class="name">{{custInfo.custNm}}</span>
          <mo-badge class="badge-sample-badge blue" text="회사제공" shape="status">{{custInfo.custSrvcTypNm}}</mo-badge>
        </div>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-pss-info">
          <div class="row">
            <span class="title">신청일자</span><span class="value">{{$cmUtil.frmtDateStr(custInfo.custSrvcAplYmd, 'yyyy-mm-dd')}}</span>
          </div>
          <div class="row">
            <span class="title">신청물품</span><span class="value">{{custInfo.gftCntnt}}</span>
          </div>
          <div class="row">
            <span class="title">결과</span><span class="value">{{custInfo.custSrvcRsltNm}}</span>
          </div>
          <div class="row">
            <span class="title">신계약현황</span><span class="value">{{custInfo.prdtNm}}<span>{{$cmUtil.frmtDateStr(custInfo.contYmd, 'yyyy-mm-dd')}}</span></span>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-active-list">
          <mo-list-item expandable ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box normal">
                  <span class="txtNormal">활동내역</span>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="pss-content">
                  <!-- <div v-if="custInfo.touchList > 0"> -->
                    <div v-for="(item, idx) in custInfo.touchList" :key="idx">
                      <div class="row">
                        <span class="title">{{item.touchNm}}</span><span class="value">{{$cmUtil.frmtDateStr(item.touchYmd, 'yyyy-mm-dd')}}</span>
                      </div>
                      <!-- <div class="row">
                        <span class="title">방문</span><span class="value">2022-06-01</span>
                      </div>
                      <div class="row">
                        <span class="title">동의서</span><span class="value">2022-06-01</span>
                      </div> -->
                    </div>
                  <!-- </div> -->
                  <!-- <div v-else> -->
                    <!-- <div class="row"> -->
                      <!-- <span class="title">최근 활동이 없습니다.</span> -->
                    <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>
            </template>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM311P",
    screenId: "MSPCM311P",
    props: {
      'selectedCustInfo': [Object, Array]
    },

    components: {
      
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 지점장 여부
        isMngr: false,
        // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        authCd: 'U000',
        custInfo: {}
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      this.custInfo = this.$props.selectedCustInfo
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************
       * Function명  : onPositive
       * 설명        : fdpConfirm 예 버튼 클릭 시, 콜백 함수
       ******************************************************/
      onPositive () {
        window.vue.getStore('spStore').dispatch('IS_NEARBY_AGREE', true )
        this.$emit('onPositive')
        
      },
      /*******************************************************
       * Function명  : onPositive
       * 설명        : fdpConfirm 아니오 버튼 클릭 시, 콜백 함수
       ******************************************************/
      onNegative () {
        window.vue.getStore('spStore').dispatch('IS_NEARBY_AGREE', false )
        this.$emit('onNegative')
      },

      /*****************************************
       * Function명  : fn_Back
       * 설명        : 뒤로가기 (history back)
       *****************************************/
      fn_Back () {
        this.$emit('onNegative')
      }
    }
  };
</script>
<style scoped>
</style>