/*
 * 업무구분: 프리미엄 고객사랑 서비스
 * 화 면 명: MSPCM307P_BS
 * 화면설명: 방문결과부실입력금지
 * 접근권한: FC
 * 작 성 일: 2022.07.05
 * 작 성 자: 손평국
 */
 <template data-page-screen-size="300x500">
  <ur-page-container title="New Page" :show-title="false" type="popup">
    <ur-box-container direction="column" componentid="ur_box_container_002" align-v="start" class="">
      <ur-box-container align-v="center" componentid="ur_box_container_009" direction="row" ref="_modal__title" class="ns-bs-title-login">
        <div class="title"><ur-text componentid="span_common_text_005" class size="lg">결과 부실 입력금지</ur-text></div>
        <div class="btn-close" @click="fn_OnNegative()">X</div>
      </ur-box-container>
      <ur-box-container align-v="start" componentid="ur_box_container_008" direction="column" align-h="start" class="p-6 ns-bs-contents-login">
        <div class="ns-contents-txt">
          고객사랑 방문서비스는 방문활동이 원칙입니다.
          <br><br>
          기타입력은 고객사정으로 방문전달하지 못한 경우에 한하여 결과입력 내용은 해당 고객에게 문자 안내가 됩니다. 
          <br><br>
          향후 방문 미전달건 과다 컨설턴트는 서비스제한 등 불이익이 있을 수 있습니다.
        </div>
      </ur-box-container>
      <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
        <div class="relative-div">
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnPositive()">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  export default {
    data() {
      return {};
    },
    modalProps: {
      full: false
    },
    props: {
      
    },

    mounted() {
      console.log('MSPCM30pP_BS mounted')
    },
    computed: {},
    methods: {
      /*******************************************************
      * Function명  : fn_OnPositive
      * 설명        : 예 버튼 클릭 시, 콜백 함수
      ******************************************************/
      fn_OnPositive () {
        console.log('MSPCM30pP_BS fn_OnPositive')
        this.$emit('onPositive307Bs')
      },
      
      /*******************************************************
       * Function명  : fn_OnPositive
       * 설명        : 아니오 버튼 클릭 시, 콜백 함수
      ******************************************************/
     fn_OnNegative () {
        console.log('MSPCM30pP_BS fn_OnNegative')
        this.$emit('onNegative307Bs')
      },

      /*****************************************
      * Function명  : fn_Back
      * 설명        : 뒤로가기 (history back)
      *****************************************/
      fn_Back () {
        this.$emit('onNegative307Bs')
      }
    }
  };
</script>
<style scoped>
</style>
<style lang="scss">
</style>